<template>
    <div>
        <APLAYZHeader2 />
        <div>
            <router-view />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import APLAYZHeader2 from "@/layout/header/Header2.vue";

export default defineComponent({
  name: "Layout2",
  components: {
    APLAYZHeader2
  },
  setup () {}
});
</script>
