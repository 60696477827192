<template>
    <div class="space_pc">
        <div class="header">
            <div class="header_box">
                <div class="logo">
                    <router-link
                        :to="{
                            name: 'Home',
                        }"
                    >
                        <img src="/media/img/logo.svg" alt="">
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Header2",
  methods: {
    addDownloadCount () {
      window.location.href = "/media/download/setup_aplayz.zip";
      axios
        .post("/api/installProgram/addDownloadCount")
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>

<style src="@/assets/css/font.css"></style>
